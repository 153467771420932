//@import '../../App.scss'; 
@import '../../gradient.scss'; 




$background-color: #181818; 


.construction-page { 
   // height: 100%;
    background: $background-color;
    color:white;
    padding:20px;   


    .container{ 
    margin: 100px; 
    display: flex;
    flex-direction: column;
    align-items: center;


    .h1-construction{ 
    margin: 3.0rem;
    color: white;
    font-size: 1.5rem;
    text-align: center;
    
    }


    p{        
    text-align: left;

    }



    }

}