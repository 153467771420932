@import '../../gradient.scss'; 

$gradient-degree: 0deg; 

.work-page { 

   
    background: $gradient; 
    color:white;
    padding:20px;

    .container{ 
    display: flex;
    flex-direction: column;
   
    h1{ 
    text-align: center;
    }
    p{        
    text-align: center;

    }



    }




}