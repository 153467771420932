 @import '../../gradient.scss'; 


.contact-page{ 
    background: $gradient;
    //background-color:  linear-gradient(45deg, blue, red);
    color:white;
    padding:20px;

    .container{ 
    display: flex;
    flex-direction: column;
   
    h1{ 
    text-align: center;
}

    p{        
    text-align: center;
    }

}
}